
import { Component, Vue } from "vue-property-decorator";
import App from "@/app/modules/_global/classes/App";

@Component
export default class AppLanguageComponent extends Vue {
  get appData() {
    return this.$store.getters["app/data"];
  }

  get languages() {
    return [
      { name: "Deutsch", locale: "de" },
      { name: "English", locale: "en" },
      { name: "Français", locale: "fr" }
    ];
  }

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  set appLanguage(locale) {
    this.$store
      .dispatch("profile/updateAppLanguageId", { user_app_locale: locale })
      .then(() => {
        this.$store.dispatch("app/changeLanguage", locale).then(({ data }) => {
          App.setLocale(locale, data);
        });
      });
  }
}
